import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import ScrollProgressRead from "react-scroll-progress-read"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import AuthorInfo from "../../components/author-info/author-info"

import ServicesList from "../../components/services-list/services-list"
import BlogList from "../../components/blog-list/blog-list"

import "../../styles/blog.scss"

const AdministratorPage = () => {
  const imageArticle = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "gem-blog.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Gemstones – Astrologer Aravind | Ashtamangalaprasnam Blog"
        description="For controlling your planetary influences"
        keyword="gems, gemstones, hinduism, astrology, sin, attainment, planet, starts, Ruby, Padamarag, Manikya, Pearl, Muktha, Red coral, Praval, Moonga, Emerald, Markat, Panna, Natural Yellow Sapphire, Pushyarag, Pukhraj, Diamond, Vajra, Heera, Natural Blue Sapphire, Neelam, Hessonite, Gomedh, Natural Cat’s Eye, Vaidurya, Lehsunia"
        image="../../images/gem-blog.jpg"
      />
      <div className="scroll-progress">
        <ScrollProgressRead
          backgroundColor="#CCC"
          barColor="#931a1a"
          height="5px"
          target="article"
        />
      </div>
      <section id="article" className="article">
        <section className="article-header">
          <Image
            className="article-header__image"
            fluid={imageArticle.file.childImageSharp.fluid}
            alt="Violet gemstones"
          />
          <div className="article-header__text-wrap article-header__text-wrap_violet">
            <h1 className="article__heading">Gemstones</h1>
            <p className="article__description">
              For controlling your planetary influences
            </p>
          </div>
        </section>
        <section className="container article-wrap">
          <article className="article__content">
            <p>
              Gems are born out of complex chemical reactions. These possess
              different physical properties, hence radiate different energies of
              different frequencies, resulting in changes in the lives of
              mortals. Humans in their life may face many difficulties and
              problems. These difficulties and hurdles are often caused by the
              ill placement of a planet or planets in our birth chart. The
              potential malefic planets in one’s horoscope can make the life of
              a person full of miseries and hurdles. After studying the
              horoscope, Gemstone related to that particular malefic planet
              could be prescribed.
            </p>

            <p>
              Gemstones are believed to enhance the beneficial effects of
              different planets in variant ways like by adding cosmic color to
              the soul of the wearer, by strengthening some specific aspects of
              life and by bestowing the attention and blessings of the deities.
              But these effects differ even among the most significant nine
              planets with their varied impacts and domination upon certain
              planetary malefic effects.
            </p>

            <p>Gems with astrologcal importance are listed below.</p>

            <div className="article-list-item">
              <h2 className="h4">Ruby (Padamarag and Manikya) for Sun</h2>
              <p>
                Sun is the king of planets and the soul of the Zodiac and rules
                the sign of Leo. The gem of the Sun is Natural Ruby (Padamarag
                and Manikya) and the metal is Gold.
              </p>
              <p>
                This gemstone bead has the power to endow the wearer with
                complete wealth in all forms along with good health. It bestows
                prestige, prosperity, and social esteem, freedom from eye
                defects/ailments. Those who are suffering from weak position of
                the sun will be benefitted by wearing this.
              </p>
              <p>
                Ruby Gemstone works for the people possessing health problems
                like headaches, indigestion, fevers, colic, issues related to
                bones, etc.
              </p>
              <p>
                Ruby plays a dominating role in the career path of engineers,
                merchants, legal experts, mineralogists, architects, jewelers,
                artists, diplomats, sculptors, artists, and aspiring to fame,
                stockbrokers and people of creative innovation sectors.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Pearl (Muktha) for Moon</h2>
              <p>
                The moon is the planet that rules the mind and hence rules the
                emotions. Moon rules the sign of Cancer. The gem of the Moon is
                a Natural Pearl (Muktha) and the metal is Silver.
              </p>
              <p>
                The Pearl pacifies the wearer through its radiations of
                kindness, various blessings, family, pleasure and wealth along
                with the dominating effect of harmony and also increases the
                creativity of the wearer. Influence of Pearl over the heart,
                blood and mind are considerable and it reduces stress, increases
                the self-confidence, aids learning, improves inter- personal
                relationships, enhances the mind’s faculties and brings harmony
                in martial life. If this Gemstone is worn by the women on their
                neck, it will preserve their chastity.
              </p>
              <p>
                It works for the people possessing health problems related to
                eyes, throat, heart, urine, abdomen, cough, scarcity of calcium,
                diseases pertaining to the female sex, indigestion, tiredness,
                general diseases and physical weaknesses.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Red coral (Praval or Moonga) for Mars</h2>
              <p>
                Mars considered as the strength of the Zodiac and rules the
                courage and initiative in an individual. Mars rules the signs of
                Aries and Scorpio in the horoscope. The gem of Mars is a Red
                Coral (Praval or Moonga) and the metal is Silver or Gold.
              </p>
              <p>
                Through the use of Red Coral in gold several diseases can be
                warded off and cured, particularly tropical fevers, general
                debility, impotency, fistula, weakness, lassitude, body pain,
                allergies, inflammations, bronchitis, pneumonia, chicken pox,
                jaundice and ailments pertaining to blood.
              </p>
              <p>
                Red Coral gem can improve lives of soldiers, police officers,
                electricians, surgeons and athletes. It enhances
                self-confidence, administrative capability, the power of
                utilizing the rights in social and political spheres and
                dominance.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Emerald (Markat and Panna) for Mercury</h2>
              <p>
                Mercury is the intellect, communication and humor of the Zodiac
                and rules the speech in an individual. Mercury rules the signs
                of Gemini and Virgo in the horoscope. The gem is Natural Emerald
                and the metal is Silver or Gold.
              </p>
              <p>
                Emerald gemstone provides a sense of understanding and empowers
                the vision of spirituality. This gemstone dominates the brain of
                the wearer leading it towards the positive aspects of nature. It
                provides the wearer with strong intellect leading him towards a
                pacified and triumphant life. It absorbs cold green radiation
                and enables the wearer to have perfect control of the nervous
                system, and intestines, liver, tissues, lunges, vocal cord,
                tongue and nervous system and helps in curing any ailment
                connected with these organs.
              </p>
              <p>
                It is suggested to be worn by diplomats, scientists,
                astrologers, teachers, architects, doctors, and others who work
                in the health sector, businessmen, property dealers, publishers,
                accountants, engineers, writers, officials, and intellectuals.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">
                Natural Yellow Sapphire (Pushyarag and Pukhraj) for Jupiter
              </h2>
              <p>
                Jupiter is concerned with all knowledge. Jupiter rules the signs
                of Sagittarius and Pisces of the zodiac. The gem is Natural
                Yellow Sapphire (Pushyarag and Pukhraj) and the metal is Gold.
                The Jupiter possesses an enormous benevolence and amicableness,
                which it explores through its gem yellow sapphire on earth. The
                Jupiter blesses the wearer with a pacified state of mind guiding
                him or her on the right path.
              </p>
              <p>
                It is commended for economic prosperity, comforts, and is
                particularly good for those engaged in business or industry.
                Freedom from the shadows of evil spirits and solves health
                problems such as fever, cough, rheumatism, psychological
                problems, kidney diseases etc.
              </p>
              <p>
                Yellow Sapphire bestows the wearer with the knowledge of law,
                ethics, wit, wisdom, worldly happiness, para-physical bliss,
                vitality, intelligence, long life, good health, food grains,
                prosperity, glory, inner peace, fame and spirituality.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Diamond (Vajra and Heera) for Venus</h2>
              <p>
                Venus is the planet of beauty and luxury. Venus rules the signs
                of Taurus and Libra of the Zodiac. The gem of Venus is the
                Natural Diamond (Vajra and Heera) and the metal is Gold.
              </p>
              <p>
                Diamond is beneficial to poets, painters, artists, actors and
                businessmen. The wearers of diamond can be saved from all kinds
                of ominous things and evil spirits and protects from poisons.
                Diamond increases the stamina and makes the marital life happy
                and permanent. It also improves health, checks untimely death,
                brings financial prosperity and happiness. Diamond gemstone is
                advantageous in diseases like epilepsy, impotency and
                rheumatism.
              </p>
              <p>
                Diamond or heera is destined for the zodiacs of Taurus and Libra
                or person having a weak position of Venus. It could also attract
                the opposite sex towards the wearer.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Natural Blue Sapphire (Neelam) for Saturn</h2>
              <p>
                Saturn is known as the Karmic planet. The signs of Capricorn and
                Aquarius are ruled by this Karmic planet. The gem of Saturn is
                the Natural Blue Sapphire (Neelam) and the metal is Silver and
                sometimes gold.
              </p>
              <p>
                Blue sapphire is the most potent gem. It helps the wearers in
                achieving their ambitions and more even. Blue sapphire could
                bestow the person with the true picture of his or her dreams and
                desires and could wipe out the hurdles of health, faults,
                misery, poverty and could bless the wearer with affluence,
                longevity, contentment, prosperity, strength, glory and other
                positive rays. Blue sapphire is believed to eliminate the
                negative rays in the guise of evil impacts of Saturn from the
                life of a person and awarding the native with the strong
                tranquility of mind. This could also turn your losses back to
                you in the shape of gains and could make barren women fertile.
                This also leads the person towards strong morals of life.
              </p>
              <p>
                It is advocated to be functional to the transporters, property
                dealers, financiers, policemen, contractors, dealers of
                machines, laborers, hawkers, local self-government employees and
                mill owners.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Hessonite (Gomedh) for Rahu</h2>
              <p>
                Rahu is considered a Karmic planet, is the north node. The gem
                of Rahu is Natural Hessonite (Gomedh) and the metal is Silver.
              </p>
              <p>
                It can ward off the evil influence of Rahu. It bestows the
                wearer with professional advancement, finance, health and
                happiness and it deters enemies from hatching plots against the
                wearer. This Gemstone helps the wearer to win court cases. It
                should be worn by one and all, who may be interested in
                spiritual advancements, particularly during the Rahu Dasha.
              </p>
              <p>
                Hessonite gemstone could bring positive air in the path of law
                practice, court affairs, government service, political spheres,
                gambling or any unsocial, illegal activities. The wearer becomes
                potent enough to clear off enemies and oppositions.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">
                Natural Cat’s Eye (Vaidurya or Lehsunia) for Ketu
              </h2>
              <p>
                Ketu is also considered to have Karmic influences and is the
                South node. The gem of Ketu is Natural Cat’s Eye (Vaidurya or
                Lehsunia) and the metal is Silver.
              </p>
              <p>
                The people possessing powerful placements of Saturn, Rahu and
                Ketu are advised to wear Cat’s Eye gemstone to stay away from
                obstacles in business, disturbed harmony in life and fear of
                accidents. The Cat’s Eye gem confines the strength, brightness,
                bravery, pleasure, bliss, prosperity and off springs and wipes
                off the negative impacts on the life of wearer leads him or her
                towards triumph through destroying the frames of sorrow,
                poverty, diseases, calamities and saves from evil spirits,
                hidden enemies and royal punishments. It could also turn your
                losses towards you in positive structures.
              </p>
              <p>
                The malefic effects of Ketu are to cause loss of reputation and
                to increase jealousies and rivalries as a result of the natives
                popularity. This Gemstone will ease matters considerably and
                safeguard the wearer from these.
              </p>
            </div>

            <p>
              If you have any queries{" "}
              <Link
                to="/#contact-us"
                title="Get in touch with us"
                className="link_text"
              >
                click here
              </Link>{" "}
              to contact us.
            </p>
          </article>

          <aside className="article-sidebar">
            <AuthorInfo></AuthorInfo>
          </aside>
        </section>
      </section>
      <ServicesList cssClass="blog" />
      <BlogList cssClass="blog" />
    </Layout>
  )
}

export default AdministratorPage
